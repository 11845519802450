<template>
  <div v-has-perms="rolePerm">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <div class="panel-header">
          <h1 class="page-header mr-2 d-flex align-items-center">
            {{ titleWithYardName | translate }}
          </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: YARD_ROUTES.YARD_LIST }">
                {{$t("Yards")}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: YARD_ROUTES.YARD_DETAIL, params: { id: $route.params.id } }">
                {{$t("Yard")}}
              </router-link>
            </li>
            <li class="breadcrumb-item">{{ getTitle | translate }}</li>
          </ol>
        </div>
        <panel :title="getTitle" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="other-headers-buttons">
              <b-btn
                variant="outline-primary"
                v-has-perms="uploadAttachmentRolePerm"
                :title="'Upload attachments' | translate"
                class="ml-2"
                @click="showModal"
              >
                <i class="fas fa-upload"></i>
                {{ 'Upload' | translate }}
              </b-btn>
            </template>
            <template slot="body">
              <component-table
                v-if="Array.isArray(list)"
                :list="Array.isArray(list) ? list : []"
                :goBack="goBack"
                :params="tableParams"
                :resetForm="resetForm"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @open-light-box="openLightBox"
                @update-actions="updateActions"
                @update-media="setMedia(Object.values($event))"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <LightBox
      v-if="showLightBox"
      :media="media"
      :startAt="startAt"
      @onClosed="showLightBox = false"
    />
    <ConfirmDialog
      v-if="showAddAttachments"
      ref-id="modal-delete"
      :no-close-on-backdrop="true"
      content-class="attachment-modal"
      confirm-btn-variant="primary"
      :title="$t('Add attachments')"
      :button-confirm-text="'Save' | translate"
      @confirm-dialog-close="closeModal"
      @confirm-dialog-select="onSubmit"
    >
      <template slot="body">
        <thux-vue-file-agent @update-attachments="updateAttachments"/>
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showDeleteAttachments"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this attachments?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeModal"
      @confirm-dialog-select="deleteAttachments"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="selectedList"
            :fields="attachmentFields"
          >
            <template slot="cell(image)" slot-scope="row">
              <img
                v-if="row.item.attachment_base64"
                height="64px" width="64px"
                :src="`data:image/png;base64,${row.item.attachment_base64}`"
                class="media-object" alt=""
              />
              <div
                v-else
                :title="$t('Preview not available')"
                class="attachment-preview-generic clickable"
              >
                <i class="fa fa-file-alt"></i>
              </div>
            </template>
            <template slot="cell(name)" slot-scope="row">
              <span>{{ row.item.name }}</span>
            </template>
            <template slot="cell(attachment)" slot-scope="row">
              <span>{{ row.item.simple_file_name }}</span>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_ATTACHMENTS_TYPES } from '../../yard-attachments/store'
import { ROUTES as YARD_ROUTES } from '../router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'
import YardAttachmentsTable from './YardAttachmentsTable'
import { cloneDeep } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'
import ThuxVueFileAgent from '@/components/ThuxVueFileAgent'
import { storeMixin } from '@/storeMixin'

import LightBox from 'vue-it-bigger'
import { TYPES as YARD_TYPES } from '../store'

export default {
  name: 'YardAttachmentsList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': YardAttachmentsTable,
    ConfirmDialog,
    ThuxVueFileAgent,
    LightBox
  },
  watch: {
    yard () {
      this.getComponentList()
    }
  },
  data () {
    return {
      YARD_ROUTES,
      formIsChanged: false,
      showLightBox: false,
      showAddAttachments: false,
      showDeleteAttachments: false,
      showHeader: false,
      showPagination: false,
      showFooterPagination: false,
      showCommonFilters: false,
      resetForm: false,
      initialList: [],
      attachments: [],
      media: [],
      startAt: 0,
      yard: this.$route.params.id,
      attachmentsImages: {},
      actionOption: { value: 'save_selected', text: this.$t('Save selected') },
      actions: [
        { value: 'save_selected', text: this.$t('Save selected'), permissions: 'yard_yardattachment_update' },
        { value: 'download', text: this.$t('Download selected'), permissions: 'yard_yardattachment_download' },
        { value: 'delete', text: this.$t('Delete selected'), permissions: 'yard_yardattachment_destroy' }
      ].filter(element => {
        return hasPerm(element.permissions)
      }),
      attachmentFields: [
        {
          key: 'image',
          label: this.$t('Preview'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'attachment',
          label: this.$t('File'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        }
      ],
      // Permissions
      rolePerm: 'yard_yardattachment_list',
      uploadAttachmentRolePerm: 'yard_yardattachment_create',
      actionEnablePermission: ['yard_yardattachment_enable'],
      actionDisablePermission: ['yard_yardattachment_disable']
    }
  },
  created () {
    this.$set(this, 'attachments', [])
  },
  computed: {
    ...mapGetters({
      ...YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.LIST.GETTERS,
      yardDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS.detail
    }),
    getTitle () {
      return `${this.$t('Attachments')} ${this.$t('yard')}`
    },
    getButtonTitle () {
      if (this.formIsChanged) {
        return this.$t('Cancel')
      }
      return this.$t('Close')
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return false
    },
    titleWithYardName () {
      if (this.yardDetail && this.yardDetail.code) {
        return `${this.$t('Attachments')} ${this.$t('yard')} ${this.yardDetail.name ? this.yardDetail.name : this.yardDetail.customer_name} (${this.yardDetail.code})`
      }
      if (this.list && this.list.results && this.list.results.length > 0) {
        const yardName = this.list.results[0].yard_name ? `(${this.list.results[0].yard_name})` : ''
        return `${this.$t('Attachments')} ${this.$t('yard')}} ${yardName}`
      }
      return `${this.$t('Attachments')} ${this.$t('yard')}`
    }
  },
  methods: {
    ...mapActions({
      ...YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.LIST.ACTIONS,
      create: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.DETAIL.ACTIONS.create,
      partialUpdate: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.DETAIL.ACTIONS.partialUpdate,
      delete: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.DETAIL.ACTIONS.delete,
      download: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.DETAIL.ACTIONS.download,
      getYardDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.retrieve
    }),
    ...mapMutations({
      setSelectedList: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.LIST.MUTATIONS.setSelectAll,
      setList: YARD_ATTACHMENTS_TYPES.GENERIC.yard.yardattachment.LIST.MUTATIONS.setSelectAll
    }),
    updateActions (disableDownload) {
      this.$set(this, 'formIsChanged', disableDownload)
      if (disableDownload) {
        this.$set(this, 'actionOption', { value: 'save_selected', text: this.$t('Save selected') })
        this.$set(this, 'actions', [
          { value: 'save_selected', text: this.$t('Save selected'), permissions: 'yard_yardattachment_update' },
          { value: 'delete', text: this.$t('Delete selected'), permissions: 'yard_yardattachment_destroy' }
        ].filter(element => {
          return hasPerm(element.permissions)
        }))
      } else {
        this.$set(this, 'actions', [
          { value: 'save_selected', text: this.$t('Save selected'), permissions: 'yard_yardattachment_update' },
          { value: 'download', text: this.$t('Download selected'), permissions: 'yard_yardattachment_download' },
          { value: 'delete', text: this.$t('Delete selected'), permissions: 'yard_yardattachment_destroy' }
        ].filter(element => {
          return hasPerm(element.permissions)
        }))
      }
    },
    downloadOrUpdateAttachment (instance, actionType) {
      let attachment = {
        id: instance.id,
        name: instance.name,
        attachment: instance.attachment,
        yard: this.yard,
        simple_file_name: instance.simple_file_name
      }
      if (actionType === 'download') {
        const list = attachment.simple_file_name.split('.')
        const ext = list[list.length - 1]
        return this.download({
          id: attachment.id,
          action: 'download',
          simpleFileName: `${attachment.name}.${ext}`
        })
      } else {
        if (!attachment.name) {
          attachment.name = attachment.attachment.name
        }
        attachment = storeMixin.utils.parseFormMultipart(attachment, ['attachment'])
        return this.partialUpdate(attachment)
      }
    },
    deleteAttachments () {
      if (this.selectedList.length !== 0) {
        const promises = []
        this.selectedList.forEach((instance) => {
          const attachment = {
            id: instance.id,
            name: instance.name,
            attachment: instance.attachment,
            yard: this.yard,
            simple_file_name: instance.simple_file_name
          }
          promises.push(this.delete(attachment))
        })
        Promise.all(promises).then(() => {
          this.closeModal()
          this.getYardDetail({ id: this.yard })
          this.getComponentList()
        })
      }
    },
    doAction (actionType, fieldName = 'id') {
      if ((this.selectedList.length !== 0 || this.selectAll) && actionType) {
        const promises = []
        if (actionType === 'save_selected' || actionType === 'download') {
          this.selectedList.forEach((instance) => {
            instance.yard = this.yard
            promises.push(this.downloadOrUpdateAttachment(instance, actionType))
          })
        } else if (actionType === 'delete') {
          this.$set(this, 'showDeleteAttachments', true)
          return
        }
        Promise.all(promises).then(() => {
          this.getYardDetail({ id: this.yard })
          this.getComponentList()
        })
      }
    },
    showModal () {
      this.$set(this, 'attachments', [])
      this.$set(this, 'showAddAttachments', true)
    },
    closeModal () {
      this.$set(this, 'attachments', [])
      this.$set(this, 'showAddAttachments', false)
      this.$set(this, 'showDeleteAttachments', false)
    },
    updateAttachments (attachments) {
      if (!attachments) {
        attachments = []
      }
      this.$set(this, 'attachments', attachments)
    },
    cancel () {
      if (this.formIsChanged) {
        this.setList(this.initialList)
        this.getYardDetail({ id: this.yard })
        this.setSelectedList([])
        this.setSelectAll(false)
        this.$set(this, 'resetForm', !this.resetForm)
      } else {
        this.$emit('hide')
      }
    },
    onSubmit () {
      if (this.attachments.length > 0) {
        const promises = []
        this.attachments.forEach((attachment) => {
          attachment.yard = this.yard
          promises.push(this.create(attachment))
        })
        Promise.all(promises).then(() => {
          this.closeModal()
          this.getYardDetail({ id: this.yard })
          this.getComponentList()
        })
      } else {
        this.closeModal()
      }
    },
    openLightBox (attachmentId) {
      this.$set(this, 'startAt', this.attachmentsImages[attachmentId] ? this.attachmentsImages[attachmentId] : 0)
      this.$set(this, 'showLightBox', true)
    },
    checkImageExtension (attachment) {
      return !!(
        attachment.endsWith('.jpg') ||
        attachment.endsWith('.png') ||
        attachment.endsWith('.gif') ||
        attachment.endsWith('.svg') ||
        attachment.endsWith('.bmp') ||
        attachment.endsWith('.jpeg') ||
        attachment.endsWith('.webp')
      )
    },
    setMedia (list = null) {
      const media = []
      this.attachmentsImages = {}
      list = list || this.list
      let indexOnlyImages = 0
      if (list && list.length > 0) {
        list.forEach((attachment) => {
          if (this.checkImageExtension(attachment.simple_file_name)) {
            const image = attachment.file ? attachment.file : `data:image/png;base64,${attachment.attachment_base64}`
            media.push({
              // For an image
              type: 'image', // Can be omitted for image
              thumb: image,
              src: image
            })
            this.attachmentsImages[attachment.id] = indexOnlyImages
            indexOnlyImages += 1
          }
        })
      }
      this.$set(this, 'media', media)
    },
    getComponentList () {
      this.setList({})
      this.getYardDetail({ id: this.yard })
      this.setSelectedList([])
      this.$set(this, 'media', [])
      this.$set(this, 'attachmentsImages', {})
      this.setSelectAll(false)
      this.setFilters({ filter__yard: this.yard, no_page: 'no_page' }).then(() => {
        this.setMedia()
        this.$set(this, 'initialList', cloneDeep(this.list))
      })
    }
  }
}
</script>
