<template>
  <thux-table
    :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll('code')"
    @check-none="checkNone('code')"
    @set-selected-row="setSelectedRow($event, 'code')"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)"
  >
    <template slot="work_status" slot-scope="row">
      <yard-work-status-popover
        :open-popover="
          instance &&
          row.item.id === instance.id &&
          showPopoverWorkStatusModal"
        :field-name="$t('Status of work')"
        :id="row.item.id"
        :instance="row.item"
        :there-are-fields="row.item.work_status"
        :work-status="row.item.work_status"
        :work-status-name="row.item.work_status_name"
        @show-popover-modal="showPopoverWorkStatus(row.item)"
        @close="reset()"
        @change-work-status="changeWorkStatus($event)"
      />
    </template>
    <template slot="other-buttons-actions-bottom" slot-scope="row">
      <b-btn
        variant="outline-primary"
        v-has-perms="printAgreementPDFRolePerm"
        :title="'Generate agreement' | translate"
        @click.prevent="printAgreementPDF(row.item)"
        :disabled="row.item.work_status === YARD_WORK_STATUSES.YARD_STATUS_CLOSED"
      >
       <i class="far fa-handshake"></i>

      </b-btn>
        <!-- <b-btn
          variant="outline-primary"
          :title="'Show attachments' | translate"
          @click="$emit('show-attachments', { id: row.item.id, num: row.item.number })"
        >
          <i class="fas fa-paperclip mr-1"></i>
          <span class="font-weight-bold" style="font-size: .8rem">
            {{ row.item.attachment_count ? row.item.attachment_count : 0 }}
          </span>
        </b-btn>
      <b-btn
        variant="outline-primary"
        :title="'Show inspections filtered by yard' | translate"
        :to="{ name: YARD_ROUTES.YARD_INSPECTIONS_LIST, params: { id: row.item.id }}"
      >
        <i class="fas fa-wrench"></i>
      </b-btn>
      <b-btn
        variant="outline-primary"
        :title="'Show contractors filtered by yard' | translate"
        :to="{ name: YARD_ROUTES.YARD_CONTRACTOR_LIST, params: { id: row.item.id }}"
      >
        <i class="far fa-building"></i>
      </b-btn>
      <b-btn
        variant="outline-primary"
        :title="'Show technicians filtered by yard' | translate"
        :to="{ name: YARD_ROUTES.YARD_TECHNICIANS_LIST, params: { id: row.item.id }}"
      >
        <i class="far fa-user"></i>
      </b-btn>
      <b-btn
        variant="outline-primary"
        :title="'Show documents filtered by yard' | translate"
        :to="{
          name: YARD_ROUTES.YARD_DOCUMENTS_LIST,
          params: { yardId: row.item.id }
        }"
      >
        <i class="far fa-file-alt"></i>
      </b-btn>
      <b-btn
        variant="outline-primary"
        :title="'Generate documents requests' | translate"
        @click.prevent="generateYardDocuments(row.item.id)"
        :disabled="!row.item.is_active"
      >
        <i class="fas fa-folder-plus"></i>

      </b-btn> -->
    </template>
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_TYPES } from './store'
import { ROUTES as YARD_ROUTES } from './router'
import { YARD_WORK_STATUSES } from '@/const'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import YardWorkStatusFieldPopover from '../components/YardWorkStatusFieldPopover'

export default {
  name: 'YardTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    'yard-work-status-popover': YardWorkStatusFieldPopover
  },
  data () {
    return {
      YARD_ROUTES,
      YARD_WORK_STATUSES,
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'work_status',
          label: this.$t('Status of work'),
          tdClass: 'text-wrap',
          type: 'text',
          thClass: 'text-nowrap',
          sortable: true,
          custom: true
          // value: 'work_status_name'
        },
        {
          key: 'year',
          label: this.$t('Year'),
          tdClass: 'text-wrap',
          type: 'number',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'year'
        },
        {
          key: 'number',
          label: this.$t('Number'),
          tdClass: 'text-wrap',
          type: 'number',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'number'
        },
        {
          key: 'code',
          label: this.$t('Code'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'code'
        },
        {
          key: 'clients',
          label: this.$t('Clients'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: false,
          value: 'clients_name'
        },
        {
          key: 'customer',
          label: this.$t('Customer'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'customer_name'
        },
        {
          key: 'date_start',
          label: this.$t('Date start'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'date_start'
        },
        {
          key: 'date_end',
          label: this.$t('Date end'),
          type: 'date',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'date_end'
        },
        // {
        //   key: 'date_confirmation',
        //   label: this.$t('Date confirmation'),
        //   type: 'date',
        //   thClass: 'text-nowrap',
        //   tdClass: 'text-nowrap',
        //   sortable: true,
        //   value: 'date_confirmation'
        // },
        // {
        //   key: 'date_activation',
        //   label: this.$t('Date activation'),
        //   type: 'date',
        //   thClass: 'text-nowrap',
        //   tdClass: 'text-nowrap',
        //   sortable: true,
        //   value: 'date_activation'
        // },
        // {
        //   key: 'quotation',
        //   label: this.$t('Quotation'),
        //   type: 'text',
        //   thClass: 'text-nowrap',
        //   tdClass: 'text-nowrap',
        //   sortable: true,
        //   value: 'quotation_number'
        // },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-wrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'code', 'work_status', 'clients', 'customer', 'date_start', 'date_end', 'date_confirmation', 'date_activation', 'quotation'],
      // fieldName: 'code',
      showPopoverWorkStatusModal: false,
      instance: undefined,
      // Permissions
      printAgreementPDFRolePerm: 'yard_yard_print_agreement'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: YARD_TYPES.GENERIC.yard.yard.LIST.GETTERS.selectAll,
      selectedList: YARD_TYPES.GENERIC.yard.yard.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm(['yard_yard_retrieve'])
    }
  },
  methods: {
    ...mapMutations({
      setList: YARD_TYPES.GENERIC.yard.yard.LIST.MUTATIONS.setList,
      setSelectedList:
        YARD_TYPES.GENERIC.yard.yard.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_TYPES.GENERIC.yard.yard.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_TYPES.GENERIC.yard.yard.LIST.ACTIONS,
      update: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.update,
      generateYardDocuments: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.generateYardDocuments,
      download: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.download
    }),
    showPopoverWorkStatus (yard) {
      this.$set(this, 'instance', yard)
      this.$set(this, 'showPopoverWorkStatusModal', true)
    },
    reset () {
      this.$set(this, 'instance', {})
      this.$set(this, 'showPopoverWorkStatusModal', false)
    },
    changeWorkStatus (evt) {
      const formData = { ...this.instance, ...evt }
      this.update(formData).then(
        () => {
          this.$set(this, 'instance', {})
          this.$set(this, 'showPopoverWorkStatusModal', false)
        }
      )
    },
    printAgreementPDF (item) {
      const fileName = `${this.$t('Agreement')}_${item.code.replace(/ /g, '')}.pdf`
      this.download({ id: item.id, action: 'print-agreement', simpleFileName: fileName })
    }
  }
}
</script>
