<template>
  <div class="attachment-list">
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      :no-results-label="'There are no attachments to show'"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
    >
      <template slot="image" slot-scope="row">
        <div class="attachment-preview">
          <div
            v-if="form[row.item.id].file"
            class="attachment-preview-image"
            @click="$emit('open-light-box', row.item.id)"
          >
            <img
              :src="form[row.item.id].file"
              class="media-object clickable"
              :alt="row.item.name"
            />
            <div class="attachment-preview-overlay">
              <i class="fa fa-search"></i>
            </div>
          </div>
          <div
            v-else-if="row.item.attachment_base64"
            class="attachment-preview-image"
            @click="$emit('open-light-box', row.item.id)"
          >
            <img
              :src="`data:image/png;base64,${row.item.attachment_base64}`"
              class="media-object clickable"
              :alt="form[row.item.name]"
            />
            <div class="attachment-preview-overlay">
              <i class="fa fa-search"></i>
            </div>
          </div>
          <div
            v-else
            :title="$t('Preview not available')"
            class="attachment-preview-generic clickable"
            @click="downloadAttachment(row.item)"
          >
            <i class="fa fa-file-alt"></i>
          </div>
        </div>
      </template>
      <template slot="name" slot-scope="row">
        <b-form-input
          v-if="form[row.item.id]"
          type="text"
          v-model="form[row.item.id].name"
          :disabled="false"
          @input="updateField(row.item, $event, 'name')"
        />
      </template>
      <template slot="attachment" slot-scope="row">
        <b-form-file
          v-if="form[row.item.id]"
          v-model="form[row.item.id]._attachment"
          :placeholder="form[row.item.id].simple_file_name ? form[row.item.id].simple_file_name : $t('Choose a file or drop it here...')"
          :drop-placeholder="$t('Drop file here...')"
          @input="updateField(row.item, $event, 'attachment')"
        />
      </template>
      <template slot="other-buttons-actions-top" slot-scope="row">
        <b-btn
          :disabled="disableDownload"
          variant="outline-primary"
          v-has-perms="downloadAttachmentRolePerm"
          :title="'Download' | translate"
          @click="downloadAttachment(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
       <b-btn
          variant="outline-primary"
          v-has-perms="deleteAttachmentRolePerm"
          :title="'Delete' | translate"
          @click="showConfirmModal(row.item)"
        >
          <i class="fas fa-times"></i>
        </b-btn>
      </template>
    </thux-table>
    <ConfirmDialog
      v-if="showDeleteModal && attachmentToDelete.id"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this attachment?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="deleteAttachment"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[attachmentToDelete]"
            :fields="attachmentFields"
          >
            <template slot="cell(image)" slot-scope="row">
              <img
                v-if="row.item.attachment_base64"
                height="64px" width="64px"
                :src="`data:image/png;base64,${row.item.attachment_base64}`"
                class="media-object" alt=""
              />
              <div
                v-else
                :title="$t('Preview not available')"
                class="attachment-preview-generic clickable"
              >
                <i class="fa fa-file-alt"></i>
              </div>
            </template>
            <template slot="cell(name)" slot-scope="row">
              <span>{{ row.item.name }}</span>
            </template>
            <template slot="cell(attachment)" slot-scope="row">
              <span>{{ row.item.simple_file_name }}</span>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_ATTACHMENT_TYPES } from '../../yard-attachments/store'

import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '@/components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import ConfirmDialog from '@/components/ConfirmDialog'
import { cloneDeep } from 'lodash'
import { checkIfEqual } from '@/const'
import { TYPES as YARD_TYPES } from '../store'

export default {
  name: 'YardInspectionAttachmentsTable',
  props: ['resetForm', 'initialList'],
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    ConfirmDialog
  },
  watch: {
    resetForm () {
      this.init()
    },
    list () {
      this.init()
    },
    selectedList () {
      if (!checkIfEqual(this.form, this.formInitial)) {
        this.$set(this, 'disableDownload', true)
      } else {
        this.$set(this, 'disableDownload', false)
      }
      this.$emit('update-actions', this.disableDownload)
    }
  },
  data () {
    return {
      showDeleteAttachment: false,
      showDeleteModal: false,
      attachmentToDelete: {},
      form: {},
      formInitial: {},
      disableDownload: false,
      attachmentFields: [
        {
          key: 'image',
          label: this.$t('Preview'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'attachment',
          label: this.$t('File'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        }
      ],
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'image',
          label: this.$t('Preview'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'name',
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'attachment',
          label: this.$t('File'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      // Permissions
      downloadAttachmentRolePerm: 'yard_yardattachment_download',
      deleteAttachmentRolePerm: 'yard_yardattachment_destroy'
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      selectAll: YARD_ATTACHMENT_TYPES.GENERIC.yard.yardattachment.LIST.GETTERS.selectAll,
      selectedList: YARD_ATTACHMENT_TYPES.GENERIC.yard.yardattachment.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList:
        YARD_ATTACHMENT_TYPES.GENERIC.yard.yardattachment.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_ATTACHMENT_TYPES.GENERIC.yard.yardattachment.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_ATTACHMENT_TYPES.GENERIC.yard.yardattachment.LIST.ACTIONS,
      delete: YARD_ATTACHMENT_TYPES.GENERIC.yard.yardattachment.DETAIL.ACTIONS.delete,
      getYardInspectionList: YARD_TYPES.GENERIC.yard.yard.LIST.ACTIONS.getList
    }),
    init () {
      this.$set(this, 'form', {})
      if (this.list && this.list.length > 0) {
        this.list.forEach((attachment) => {
          this.$set(this.form, attachment.id, cloneDeep(attachment))
        })
      }
      this.$set(this, 'formInitial', cloneDeep(this.form))
      this.setSelectedList([])
    },
    downloadAttachment (attachment) {
      if (!this.disableDownload) {
        const list = attachment.simple_file_name.split('.')
        const ext = list[list.length - 1]
        return this.download({
          id: attachment.id,
          action: 'download',
          simpleFileName: `${attachment.name}.${ext}`
        })
      }
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async updateField (item, value, fieldName) {
      this.$set(this.form[item.id], fieldName, value)
      if (fieldName === 'attachment') {
        if (value && value.type.split('/')[0] === 'image') {
          await this.getBase64(value)
            .then((result) => {
              this.$set(this.form[item.id], 'file', result)
              this.$emit('update-media', this.form)
            })
            .catch(() => {
              this.$set(this.form[item.id], 'file', undefined)
              this.$set(this.form[item.id], 'attachment_base64', undefined)
              this.$emit('update-media', this.form)
            })
        } else {
          this.$set(this.form[item.id], 'file', undefined)
          this.$set(this.form[item.id], 'attachment_base64', undefined)
          this.$emit('update-media', this.form)
        }
      }
      if (value !== null) {
        this.updateSelectedRow(item)
      }
    },
    updateSelectedRow (value) {
      const selectedList = [...this.selectedList]
      let findInstance = false
      if (checkIfEqual(this.form, this.formInitial)) {
        return
      }
      if (this.selectedList) {
        this.selectedList.forEach((instance, index) => {
          if (instance.id === value.id) {
            selectedList[index] = this.form[value.id]
            findInstance = true
          }
        }, 500)
      }
      if (!findInstance) {
        selectedList.push(this.form[value.id])
      }
      this.setSelectedList(selectedList)
    },
    showConfirmModal (instance) {
      this.$set(this, 'attachmentToDelete', instance)
      this.$set(this, 'showDeleteModal', true)
    },
    closeConfirmModal () {
      this.$set(this, 'attachmentToDelete', {})
      this.$set(this, 'showDeleteModal', false)
    },
    deleteAttachment () {
      const promise = this.delete(this.attachmentToDelete)
      promise.then(
        () => {
          this.getYardInspectionList()
          setTimeout(() => {
            this.$emit('refresh-list')
            this.closeConfirmModal()
          }, 500)
        }
      )
    }
  }
}
</script>
