<template>
  <div v-if="id" class="d-flex">
    <div class="btn-add-worker">
      <v-popover
        offset="16"
        trigger="click"
        placement="top"
        :open="openPopover"
        :auto-hide="false"
      >
        <slot name="body">
          <span
            v-if="workStatus"
            class="link-item"
            :title="$t('{action} assigned by', { action: $t('Add') })"
            @click.prevent="$emit('show-popover-modal')"
          >
            {{ workStatusName }}
          </span>
        </slot>
        <template v-if="openPopover" slot="popover">
          <div class="popover-field">
            <div class="popover-header">
              {{ `${$t('Edit')} ${$t(fieldName)}` }}
            </div>
              <form-group-thux
                :label="'Status of work' | translate"
                class="col-12 mb-3 p-0"
                :validator="$v.form.work_status"
              >
                <multiselect-thux
                  label="text"
                  attributetype="select"
                  :close-on-select="true"
                  :value="form.work_status ? { value: form.work_status, text: form.work_status_name } : null"
                  :options="workStatusListOptions"
                  :placeholder="$t('Select an option')
                  "
                  @select="selectWorkStatus"
                  @clear="clearWorkStatus"
                >
                </multiselect-thux>
              </form-group-thux>
              <form-group-thux
                v-if="form.work_status === 'CON' "
                :label="'Confirmation date' | translate"
                class="col-12 mb-3 p-0"
                :validator="$v.form.date_confirmation"
              >
                <div class="input-group">
                  <date-picker
                    v-model="dateConfirmation"
                    :config="confirmationDateFieldConfig"
                    :placeholder="$t('Confirmation date')"
                    @dp-change="dateChange($event, 'date_confirmation')"
                  />
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fas fa-calendar"></i>
                    </label>
                  </div>
                </div>
              </form-group-thux>
              <form-group-thux
                v-if="form.work_status === 'OP' "
                :label="'Activation date' | translate"
                class="col-12 mb-3 p-0"
                :validator="$v.form.date_activation"
              >
                <div class="input-group">
                  <date-picker
                    v-model="dateActivation"
                    :config="activationDateFieldConfig"
                    :placeholder="$t('Activation date')"
                    @dp-change="dateChange($event, 'date_activation')"
                  />
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fas fa-calendar"></i>
                    </label>
                  </div>
                </div>
              </form-group-thux>
              <form-group-thux
                v-if="form.work_status === 'CMP' "
                :label="'Date end' | translate"
                class="col-12 mb-3 p-0"
                :validator="$v.form.date_end"
              >
                <div class="input-group">
                  <date-picker
                    v-model="dateEnd"
                    :config="endDateFieldConfig"
                    :placeholder="$t('Date end')"
                    @dp-change="dateChange($event, 'date_end')"
                  />
                  <div class="input-group-append">
                    <label class="input-group-text">
                      <i class="fas fa-calendar"></i>
                    </label>
                  </div>
                </div>
              </form-group-thux>
            <div class="popover-footer">
              <b-btn
                class="btn btn-outline-secondary"
                @click.prevent="$emit('close')"
              >
                {{ 'Cancel' | translate }}
              </b-btn>
              <b-btn
                :disabled="$v.$invalid"
                class="btn btn-primary"
                @click.prevent="$emit('change-work-status', form)"
              >
                <span >{{ 'Edit' | translate }}</span>
              </b-btn>
            </div>
          </div>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
// import { TYPES as YARD_TYPES } from '../yard/store'
import { required } from 'vuelidate/lib/validators'
import WorkStatusMixin from '../yard/mixins/WorkStatusMixin'
import MultiselectThux from '@/components/MultiselectThux'

export default {
  name: 'YardWorkStatusFieldPopover',
  mixins: [WorkStatusMixin],
  props: {
    placement: {},
    openPopover: {
      required: true
    },
    instance: {
      required: true
    },
    closeOnSelect: {
      default: false
    },
    id: {
      required: true
    },
    fieldName: {
      required: true
    },
    thereAreFields: {
      required: true
    },
    disabledButton: {},
    workStatus: { },
    workStatusName: { }
  },
  watch: {
    openPopover () {
      this.init()
    }
  },
  components: {
    'multiselect-thux': MultiselectThux
  },
  data () {
    const icons = {
      time: 'fas fa-clock',
      date: 'fas fa-calendar',
      up: 'fas fa-angle-up',
      down: 'fas fa-angle-down',
      previous: 'fas fa-angle-left',
      next: 'fas fa-angle-right',
      today: 'fas fa-calendar-alt',
      clear: 'far fa-trash-alt',
      close: 'fas fa-times'
    }
    return {
      form: {
        work_status: {}
      },
      dateEnd: null,
      dateConfirmation: null,
      dateActivation: null,
      minConfirmationDate: null,
      maxConfirmationDate: null,
      confirmationDateFieldConfig: {
        format: 'L',
        useCurrent: false,
        showClear: true,
        locale: this.$i18n.locale(),
        icons: icons
      },
      activationDateFieldConfig: {
        format: 'L',
        useCurrent: false,
        showClear: true,
        locale: this.$i18n.locale(),
        icons: icons
      },
      endDateFieldConfig: {
        format: 'L',
        useCurrent: false,
        showClear: true,
        locale: this.$i18n.locale(),
        icons: icons
      }
    }
  },
  created () {
    this.init()
  },
  computed: {
  },
  methods: {
    init () {
      this.$set(this, 'form', { work_status: {} })
      if (this.workStatus) {
        this.$set(this.form, 'work_status', this.workStatus)
        this.$set(this.form, 'work_status_name', this.workStatusName)
      }
      if (this.instance) {
        if (this.instance.date_confirmation) {
          this.$set(this, 'dateConfirmation', moment(new Date(this.instance.date_confirmation)))
          this.$set(this.form, 'date_confirmation', this.instance.date_confirmation)
        }
        if (this.instance.date_activation) {
          this.$set(this, 'dateActivation', moment(new Date(this.instance.date_activation)))
          this.$set(this.form, 'date_activation', this.instance.date_activation)
        }
        if (this.instance.date_end) {
          this.$set(this, 'dateEnd', moment(new Date(this.instance.date_end)))
          this.$set(this.form, 'date_end', this.instance.date_end)
        }
        if (this.instance.date_start) {
          this.$set(this.confirmationDateFieldConfig, 'minDate', moment(new Date(this.instance.date_start)))
          this.$set(this.activationDateFieldConfig, 'minDate', moment(new Date(this.instance.date_start)))
          this.$set(this.endDateFieldConfig, 'minDate', moment(new Date(this.instance.date_start)))
        }
        if (this.instance.date_end) {
          this.$set(this.confirmationDateFieldConfig, 'maxDate', moment(new Date(this.instance.date_end)))
          this.$set(this.activationDateFieldConfig, 'maxDate', moment(new Date(this.instance.date_end)))
        }
      }
    },
    dateChange (evt, fieldName, format = 'YYYY-MM-DD') {
      const date = evt.date
      if (date) {
        this.$set(this.form, fieldName, date.format(format))
      } else {
        this.$set(this.form, fieldName, undefined)
      }
    }
  },
  validations () {
    const vals = {
      form: {
        work_status: { required },
        date_confirmation: { },
        date_activation: { },
        date_end: { }
      }
    }
    if (this.form.work_status === 'CON') {
      vals.form.date_confirmation = { required }
    }
    if (this.form.work_status === 'OP') {
      vals.form.date_activation = { required }
    }
    if (this.form.work_status === 'CMP') {
      vals.form.date_end = { required }
    }
    return vals
  }
}
</script>
