<template>
  <div :class="!isDetail ? 'wrapper sidebar-collapsed' : ''">
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <thux-list-header
          v-if="showHeader"
          :title="title"
          :go-to="goToRouter"
          :icon-active-filters="getIconFiltersFields"
          :intermediate-breadcrumbs="intermediateBreadcrumbs"
        />
        <yard-attachments-list
          v-if="showAttachments"
          :yard="yard.id"
          @hide="showAttachments = false"
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="panelTitle" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @show-attachments="showYardAttachments"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
    </portal-target>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_TYPES } from './store'
import { ROUTES as YARD_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import YardTable from './YardTable'
import YardCommonFilters from './YardCommonFilters'
import YardAttachmentsList from './attachments/YardAttachmentsList'

export default {
  name: 'YardList',
  mixins: [ThuxListMixin],
  components: {
    YardAttachmentsList,
    'component-table': YardTable,
    'common-filters': YardCommonFilters

  },
  data () {
    return {
      YARD_ROUTES,
      title: this.$t('Yards'),
      rolePerm: ['yard_yard_list'],
      actionEnablePermission: ['yard_yard_enable'],
      actionDisablePermission: ['yard_yard_disable'],
      yard: {},
      showAttachments: false,
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__client__name__icontains', placeholder: this.$t('Client'), type: 'text', col: 6 },
        { key: 'filter__customer__name__icontains', placeholder: this.$t('Customer'), type: 'text', col: 6 },
        { key: 'filter__description__icontains', placeholder: this.$t('Description'), type: 'text', col: 6 },
        { key: 'filter__date_start__gte', placeholder: this.$t('Date begin greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_start__lte', placeholder: this.$t('Date begin less than equal'), minDate: 'filter__date_start__gte', type: 'date', col: 6 },
        { key: 'filter__date_end__gte', placeholder: this.$t('Date end greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_end__lte', placeholder: this.$t('Date end less than equal'), minDate: 'filter__date_end__gte', type: 'date', col: 6 },
        { key: 'filter__date_confirmation__gte', placeholder: this.$t('Date confirmation greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_confirmation__lte', placeholder: this.$t('Date confirmation less than equal'), minDate: 'filter__date_confirmation__gte', type: 'date', col: 6 },
        { key: 'filter__date_activation__gte', placeholder: this.$t('Date activation greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_activation__lte', placeholder: this.$t('Date activation less than equal'), minDate: 'filter__date_activation__gte', type: 'date', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_TYPES.GENERIC.yard.yard.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.YARD_ROUTES.YARD_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('yard_yard_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: YARD_TYPES.GENERIC.yard.yard.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_TYPES.GENERIC.yard.yard.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_TYPES.GENERIC.yard.yard.LIST.ACTIONS
    }),
    openEditForm () {
      this.$router.push({ name: YARD_ROUTES.YARD_CREATE })
    },
    openDetailForm (id) {
      this.$router.push({ name: YARD_ROUTES.YARD_DETAIL, params: { id: id } })
    },
    showYardAttachments (yard) {
      this.$set(this, 'yard', yard)
      this.$set(this, 'showAttachments', true)
    }
  }
}
</script>
